import React from 'react'
import { useTranslation } from 'i18n'
import { FasticLogo } from '@components/FasticLogo'
import ChatIcon from '@components/ChatIcon'

const Footer: React.FC<{ version: string }> = React.memo(({ version }) => {
    const { t } = useTranslation()
    return (
        <div className="bg-white w-full overflow-hidden pt-6 md:pt-12 pb-7 relative z-10">
            <div className="px-5 mx-auto w-full max-w-4xl">
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="flex flex-col text-center items-center md:text-left md:items-start">
                        <FasticLogo className="w-20 mb-5" />
                        <p
                            className="mt-2.5 mb-5 leading-snug text-sm text-gray-400"
                            dangerouslySetInnerHTML={{
                                __html: t([`landing:footer.about.${version}`, 'landing:footer.about.default']),
                            }}
                        />
                        <div className="flex flex-col md:flex-row leading-snug text-sm text-gray-400">
                            <a href="https://fastic.com/privacy-policy" className="text-gray-500 underline">
                                {t([
                                    `landing:footer.privacy_policy.${version}`,
                                    'landing:footer.privacy_policy.default',
                                ])}
                            </a>
                            <span className="hidden md:block mx-2.5">|</span>
                            <a href="https://fastic.com/terms" className="text-gray-500 underline mt-2 md:mt-0">
                                {t([`landing:footer.terms.${version}`, 'landing:footer.terms.default'])}
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col text-center items-center">
                        <ChatIcon className="w-12 text-yellow-600" />
                        <h2 className="text-lg text-yellow-600 my-2">
                            {t([`landing:footer.need_help.${version}`, 'landing:footer.need_help.default'])}
                        </h2>
                        <p className="mt-1 mb-5 leading-snug text-sm text-gray-400 w-2/3">
                            {t([`landing:footer.contact_us.${version}`, 'landing:footer.contact_us.default'])}
                        </p>
                        <a href="mailto:support@fastic.com" className="text-yellow-600 hover:underline text-lg">
                            support@fastic.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Footer
